<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">全社月別ダッシュボード</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <a-form layout="inline">
              <span style="margin-right: 5px;">会社選択</span>
              <a-select
                show-search
                :style="'min-width: 200px; max-width: 40%; margin-right: 15px;'"
                @change="changeSelectedCompanyName"
                :filter-option="filterOption"
              >
                <a-select-option
                  key="0"
                  value="0"
                  selected
                >
                  選択しない
                </a-select-option>
                <a-select-option
                  v-for="item in companies"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>

              <span style="margin-right: 5px;">年</span>
              <a-select
                :style="'min-width: 30px; max-width: 20%; margin-right: 15px;'"
                @change="changeSelectedYear"
                :defaultValue="'2022年'"
              >
                <a-select-option
                  v-for="n in 10"
                  :key="n"
                  :value="String(n + 2019)"
                >
                  {{ n + 2019 }} 年
                </a-select-option>
              </a-select>
              <a-button type="primary" @click="getSummaryData()">取得</a-button>
            </a-form>
            <hr style="margin: 20px 0 0 0">
          </div>

          <div class="card-body">
            <div v-if="dataLoading" class="text-center" >
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!dataLoading && dataShow">
              <p class="text-right"><a-button type="primary" @click="outputCSV()">CSV出力</a-button></p>
              <h2>データ更新タイミング</h2>
              <table>
                <thead class="ant-table-thead">
                  <tr>
                    <th>月</th>
                    <th v-for="month in 12" :key="month" class="text-center">{{ month }}月</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>更新日時</td>
                    <td v-for="(item, index) in companyData[0]['data']['all']" :key="index">{{ item['analyzed_datetime'] }}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td v-for="(item, key, index) in companyData[0]['data']['all']" :key="index"><a-button block @click="updateMonthrySummary(key)">更新する</a-button></td>
                  </tr>
                </tbody>
              </table>
              <hr style="margin-bottom: 40px;">

              <h2>全社</h2>
              <div v-for="(roadName, road, index) in roadType" :key="index" style="margin-bottom: 20px;">
                <h4>{{ roadName }}</h4>
                <table>
                  <thead class="ant-table-thead">
                    <tr>
                      <th></th>
                      <th v-for="month in 12" :key="month" class="text-center">{{ month }}月</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>全体遵守率</td>
                      <td v-for="(item, index) in companyData[0]['data'][road]" :key="index">{{ (((item['r_total'] - item['v_total']) / item['r_total']) * 100).toFixed(3) | percentValue }}</td>
                    </tr>
                    <tr>
                      <td>違反種類別遵守率</td>
                      <td colspan="12"></td>
                    </tr>
                    <tr>
                      <td>速度超過</td>
                      <td v-for="(item, index) in companyData[0]['data'][road]" :key="index">{{ (((item['r_m_s'] - item['v_m_s']) / item['r_m_s']) * 100).toFixed(3) | percentValue }}</td>
                    </tr>
                    <tr>
                      <td>一時不停止</td>
                      <td v-for="(item, index) in companyData[0]['data'][road]" :key="index">{{ (((item['r_t_s'] - item['v_t_s']) / item['r_t_s']) * 100).toFixed(3) | percentValue }}</td>
                    </tr>
                    <tr>
                      <td>通行禁止</td>
                      <td v-for="(item, index) in companyData[0]['data'][road]" :key="index">{{ (((item['r_o'] - item['v_o']) / item['r_o']) * 100).toFixed(3) | percentValue }}</td>
                    </tr>
                    <tr>
                      <td>踏切不停止</td>
                      <td v-for="(item, index) in companyData[0]['data'][road]" :key="index">{{ (((item['r_r_s'] - item['v_r_s']) / item['r_r_s']) * 100).toFixed(3) | percentValue }}</td>
                    </tr>
                    <tr>
                      <td>速度超過内訳</td>
                      <td colspan="12"></td>
                    </tr>
                    <tr>
                      <td>20未満</td>
                      <td v-for="(item, index) in companyData[0]['data'][road]" :key="index">{{ ((item['v_m_s_u20'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                    </tr>
                    <tr>
                      <td>20以上25未満</td>
                      <td v-for="(item, index) in companyData[0]['data'][road]" :key="index">{{ ((item['v_m_s_20_25'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                    </tr>
                    <tr>
                      <td>25以上30未満</td>
                      <td v-for="(item, index) in companyData[0]['data'][road]" :key="index">{{ ((item['v_m_s_25_30'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                    </tr>
                    <tr>
                      <td>30以上50未満</td>
                      <td v-for="(item, index) in companyData[0]['data'][road]" :key="index">{{ ((item['v_m_s_30_50'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                    </tr>
                    <tr>
                      <td>50以上</td>
                      <td v-for="(item, index) in companyData[0]['data'][road]" :key="index">{{ ((item['v_m_s_50u'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr style="margin-bottom: 40px;">
              <h2>支店</h2>
              <div v-for="(targetData, index) in branchData" :key="index + 'b'" style="margin-bottom: 20px;">
                <h3>{{ targetData['info']['name'] }}</h3>
                <div v-for="(roadName, road, index) in roadType" :key="index" style="margin-bottom: 10px;">
                  <h4>{{ roadName }}</h4>
                  <table>
                    <thead class="ant-table-thead">
                      <tr>
                        <th></th>
                        <th v-for="month in 12" :key="month" class="text-center">{{ month }}月</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>全体遵守率</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_total'] - item['v_total']) / item['r_total']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>違反種類別遵守率</td>
                        <td colspan="12"></td>
                      </tr>
                      <tr>
                        <td>速度超過</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_m_s'] - item['v_m_s']) / item['r_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>一時不停止</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_t_s'] - item['v_t_s']) / item['r_t_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>通行禁止</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_o'] - item['v_o']) / item['r_o']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>踏切不停止</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_r_s'] - item['v_r_s']) / item['r_r_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>速度超過内訳</td>
                        <td colspan="12"></td>
                      </tr>
                      <tr>
                        <td>20未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_u20'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>20以上25未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_20_25'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>25以上30未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_25_30'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>30以上50未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_30_50'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>50以上</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_50u'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <hr style="margin-bottom: 40px;">
              <h2>車両</h2>
              <div v-for="(targetData, index) in vehicleData" :key="index + 'v'" style="margin-bottom: 20px;">
                <h3>{{ targetData['info']['name'] }}</h3>
                <div v-for="(roadName, road, index) in roadType" :key="index" style="margin-bottom: 10px;">
                  <h4>{{ roadName }}</h4>
                  <table>
                    <thead class="ant-table-thead">
                      <tr>
                        <th></th>
                        <th v-for="month in 12" :key="month" class="text-center">{{ month }}月</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>全体遵守率</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_total'] - item['v_total']) / item['r_total']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>違反種類別遵守率</td>
                        <td colspan="12"></td>
                      </tr>
                      <tr>
                        <td>速度超過</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_m_s'] - item['v_m_s']) / item['r_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>一時不停止</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_t_s'] - item['v_t_s']) / item['r_t_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>通行禁止</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_o'] - item['v_o']) / item['r_o']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>踏切不停止</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_r_s'] - item['v_r_s']) / item['r_r_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>速度超過内訳</td>
                        <td colspan="12"></td>
                      </tr>
                      <tr>
                        <td>20未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_u20'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>20以上25未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_20_25'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>25以上30未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_25_30'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>30以上50未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_30_50'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>50以上</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_50u'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <hr style="margin-bottom: 40px;">
              <h2>ユーザー</h2>
              <div v-for="(targetData, index) in userData" :key="index + 'u'" style="margin-bottom: 20px;">
                <h3>{{ targetData['info']['name'] }}</h3>
                <div v-for="(roadName, road, index) in roadType" :key="index" style="margin-bottom: 10px;">
                  <h4>{{ roadName }}</h4>
                  <table>
                    <thead class="ant-table-thead">
                      <tr>
                        <th></th>
                        <th v-for="month in 12" :key="month" class="text-center">{{ month }}月</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>全体遵守率</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_total'] - item['v_total']) / item['r_total']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>違反種類別遵守率</td>
                        <td colspan="12"></td>
                      </tr>
                      <tr>
                        <td>速度超過</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_m_s'] - item['v_m_s']) / item['r_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>一時不停止</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_t_s'] - item['v_t_s']) / item['r_t_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>通行禁止</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_o'] - item['v_o']) / item['r_o']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>踏切不停止</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ (((item['r_r_s'] - item['v_r_s']) / item['r_r_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>速度超過内訳</td>
                        <td colspan="12"></td>
                      </tr>
                      <tr>
                        <td>20未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_u20'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>20以上25未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_20_25'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>25以上30未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_25_30'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>30以上50未満</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_30_50'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                      <tr>
                        <td>50以上</td>
                        <td v-for="(item, index) in targetData['data'][road]" :key="index">{{ ((item['v_m_s_50u'] / item['v_m_s']) * 100).toFixed(3) | percentValue }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

function totalData() {
  return {
    drive_data_count: 0,
    driving_time: 0,
    distance: 0,
    r_total: 0,
    r_t_s: 0, // regulation_temporary_stop
    r_m_s: 0, // regulation_maximum_speed
    r_r_s: 0, // regulation_railroad_stop
    r_o: 0, // regulation_oneway
    r_s_d: 0, // regulation_specified_direction
    r_n_p: 0, // regulation_no_parking
    r_n_s: 0, // regulation_no_stop
    v_total: 0,
    v_m_s: 0,
    v_m_s_u20: 0,
    v_m_s_20_25: 0,
    v_m_s_25_30: 0,
    v_m_s_30_50: 0,
    v_m_s_50u: 0,
    v_t_s: 0,
    v_r_s: 0,
    v_o: 0,
    // 駐停車違反: 0,
    // 停車禁止: 0,
    // 駐車禁止違反: 0,
    v_a_t_s: 0, // violation_announced_temporary_stop
    v_a_r_s: 0, // violation_announced_railroad_stop
    a_t_1: 0, // announce type 1-x
    a_t_2: 0, // announce type 2-x
    a_t_3: 0, // announce type 3-x
    a_t_4: 0, // announce type 4-x
    a_t_51: 0, // announce type 5-1
    a_t_52: 0, // announce type 5-2
    analyzed_datetime: null,
  }
}

function initialData() {
  // Company, Branch, Vehicle, User について以下の配列を作っていく
  // info: ['name', 'id']
  // data: 月ごとの totalData の配列
  return {
    info: {
      id: null,
      name: '',
    },
    data: {
      all: {},
      general: {},
      narrow: {},
    },
  }
}

export default {
  data() {
    return {
      roadType: {
        all: 'すべての道路',
        general: '一般道路',
        narrow: '生活道路',
      },

      companies: [],
      dataLoading: true,
      dataShow: false,
      selectedCompany: '0',
      selectedCompanyName: '',
      selectedYear: '2022',
      excludeViolationTypes: [],

      companyData: [initialData()],
      branchData: {},
      vehicleData: {},
      userData: {},
    }
  },
  created() {
    const companyList = Vue.prototype.$api.send('get', 'companies')
    companyList.then(response => {
      this.dataLoading = false
      this.companies = response
      console.log(response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 会社の取得に失敗しました。',
        })
        this.dataLoading = false
      })
    console.log('message')
  },
  filters: {
    percentValue: function (val) {
      if (val === 'NaN') return ''
      else return val + ' %'
    },
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    changeSelectedYear(val) { this.selectedYear = val },
    changeSelectedCompanyName(val) {
      this.selectedCompany = val
      if (val !== '0') {
        this.selectedCompanyName = this.companies.find(e => e.id === val).name
      } else {
        this.selectedCompanyName = '選択しない'
      }
    },
    getSummaryData() {
      const _this = this
      this.companyData = [initialData()]
      this.branchData = {}
      this.vehicleData = {}
      this.userData = {}
      this.dataLoading = true

      for (let i = 1; i <= 12; i++) {
        const month = this.selectedYear + '-' + ('00' + i).slice(-2) + '-01'
        this.companyData[0]['data']['all'][month] = totalData()
        this.companyData[0]['data']['general'][month] = totalData()
        this.companyData[0]['data']['narrow'][month] = totalData()
      }

      if (this.selectedCompany !== '0') {
        this.excludeViolationTypes = this.companies.find(e => e.id === this.selectedCompany).exclude_violations.map(e => { return e.violation_type })
        const getData = {
          year: this.selectedYear,
        }
        const companyList = Vue.prototype.$api.send('get', 'monthly_drive_data_summary/' + this.selectedCompany, getData)
        companyList.then(response => {
          console.log('monthlyData: ', response)

          for (var key in response) {
            const dds = response[key]
            const branchId = String(dds.branch_id)
            const vehicleId = String(dds.vehicle_id)
            const userId = String(dds.user_id)
            if (!this.branchData[branchId]) {
              this.branchData[branchId] = initialData()
              this.branchData[branchId]['info']['id'] = dds.branch_id
              this.branchData[branchId]['info']['name'] = dds.branch_name
              for (let i = 1; i <= 12; i++) {
                const month = this.selectedYear + '-' + ('00' + i).slice(-2) + '-01'
                this.branchData[branchId]['data']['all'][month] = totalData()
                this.branchData[branchId]['data']['general'][month] = totalData()
                this.branchData[branchId]['data']['narrow'][month] = totalData()
              }
            }
            if (!this.vehicleData[vehicleId]) {
              this.vehicleData[vehicleId] = initialData()
              this.vehicleData[vehicleId]['info']['id'] = dds.vehicle_id
              this.vehicleData[vehicleId]['info']['name'] = dds.vehicle_name
              for (let i = 1; i <= 12; i++) {
                const month = this.selectedYear + '-' + ('00' + i).slice(-2) + '-01'
                this.vehicleData[vehicleId]['data']['all'][month] = totalData()
                this.vehicleData[vehicleId]['data']['general'][month] = totalData()
                this.vehicleData[vehicleId]['data']['narrow'][month] = totalData()
              }
            }
            if (dds.user_id !== 0 && !this.userData[userId]) {
              this.userData[userId] = initialData()
              this.userData[userId]['info']['id'] = dds.user_id
              this.userData[userId]['info']['name'] = dds.user_name
              for (let i = 1; i <= 12; i++) {
                const month = this.selectedYear + '-' + ('00' + i).slice(-2) + '-01'
                this.userData[userId]['data']['all'][month] = totalData()
                this.userData[userId]['data']['general'][month] = totalData()
                this.userData[userId]['data']['narrow'][month] = totalData()
              }
            }
            _this.dataArrange(dds)
          }
          this.dataShow = true
          this.dataLoading = false
        })
          .catch(error => {
            this.$notification['error']({
              message: error.status + ': 月別データの取得に失敗しました。',
            })
            this.dataLoading = false
          })
      } else {
        this.excludeViolationTypes = []
        this.dataShow = false
        this.dataLoading = false
      }
    },
    dataArrange(dds) {
      const targetArray = ['companyData', 'branchData', 'vehicleData', 'userData']
      const idArray = [0, String(dds.branch_id), String(dds.vehicle_id), String(dds.user_id)]
      const regulationArray = ['r_t_s', 'r_m_s', 'r_r_s', 'r_o']
      const regulationGeneralArray = ['r_g_t_s', 'r_g_m_s', 'r_g_r_s', 'r_g_o']
      const regulationNarrowArray = ['r_n_t_s', 'r_n_m_s', 'r_n_r_s', 'r_n_o']
      const violationArray = ['v_t_s', 'v_r_s', 'v_o']
      const violationGeneralArray = ['v_g_t_s', 'v_g_r_s', 'v_g_o']
      const violationNarrowArray = ['v_n_t_s', 'v_n_r_s', 'v_n_o']
      const violationSpeedArray = ['v_m_s_u20', 'v_m_s_20_25', 'v_m_s_25_30', 'v_m_s_30_50', 'v_m_s_50u']
      const violationSpeedGeneralArray = ['v_g_m_s_u20', 'v_g_m_s_20_25', 'v_g_m_s_25_30', 'v_g_m_s_30_50', 'v_g_m_s_50u']
      const violationSpeedNarrowArray = ['v_n_m_s_u20', 'v_n_m_s_20_25', 'v_n_m_s_25_30', 'v_n_m_s_30_50', 'v_n_m_s_50u']

      const excludeMaximumSpeed = this.excludeViolationTypes.includes(1) && this.excludeViolationTypes.includes(2) && this.excludeViolationTypes.includes(3) && this.excludeViolationTypes.includes(4) && this.excludeViolationTypes.includes(5)
      const excludeOneWay = this.excludeViolationTypes.includes(8) && this.excludeViolationTypes.includes(9)

      for (let target = 0; target < targetArray.length; target++) {
        const targetData = targetArray[target]
        if (targetData !== 'companyData' && (!idArray[target] || !this[targetData][idArray[target]])) continue

        // 運行数、走行距離、運転時間
        this[targetData][idArray[target]]['data']['all'][dds['date']]['drive_data_count'] += dds['drive_data_count']
        this[targetData][idArray[target]]['data']['all'][dds['date']]['driving_time'] += dds['driving_time']
        this[targetData][idArray[target]]['data']['all'][dds['date']]['distance'] += dds['distance']

        // 規制件数
        for (let i = 0; i < regulationArray.length; i++) {
          if (
            (this.excludeViolationTypes.includes(6) && regulationArray[i] === 'r_t_s') ||
            (this.excludeViolationTypes.includes(7) && regulationArray[i] === 'r_r_s') ||
            (excludeOneWay && regulationArray[i] === 'r_o') ||
            (excludeMaximumSpeed && regulationArray[i] === 'r_m_s')
          ) continue
          this[targetData][idArray[target]]['data']['all'][dds['date']]['r_total'] += dds[regulationArray[i]]
          this[targetData][idArray[target]]['data']['general'][dds['date']]['r_total'] += dds[regulationGeneralArray[i]]
          this[targetData][idArray[target]]['data']['narrow'][dds['date']]['r_total'] += dds[regulationNarrowArray[i]]
          this[targetData][idArray[target]]['data']['all'][dds['date']][regulationArray[i]] += dds[regulationArray[i]]
          this[targetData][idArray[target]]['data']['general'][dds['date']][regulationArray[i]] += dds[regulationGeneralArray[i]]
          this[targetData][idArray[target]]['data']['narrow'][dds['date']][regulationArray[i]] += dds[regulationNarrowArray[i]]
        }

        // 違反情報
        for (let i = 0; i < violationArray.length; i++) {
          if (
            (this.excludeViolationTypes.includes(6) && violationArray[i] === 'v_t_s') ||
            (this.excludeViolationTypes.includes(7) && violationArray[i] === 'v_r_s') ||
            (excludeOneWay && violationArray[i] === 'v_o')
          ) continue
          this[targetData][idArray[target]]['data']['all'][dds['date']]['v_total'] += dds[violationArray[i]]
          this[targetData][idArray[target]]['data']['general'][dds['date']]['v_total'] += dds[violationGeneralArray[i]]
          this[targetData][idArray[target]]['data']['narrow'][dds['date']]['v_total'] += dds[violationNarrowArray[i]]
          this[targetData][idArray[target]]['data']['all'][dds['date']][violationArray[i]] += dds[violationArray[i]]
          this[targetData][idArray[target]]['data']['general'][dds['date']][violationArray[i]] += dds[violationGeneralArray[i]]
          this[targetData][idArray[target]]['data']['narrow'][dds['date']][violationArray[i]] += dds[violationNarrowArray[i]]
        }
        for (let i = 0; i < violationSpeedArray.length; i++) {
          if (
            (this.excludeViolationTypes.includes(1) && violationSpeedArray[i] === 'v_m_s_u20') ||
            (this.excludeViolationTypes.includes(2) && violationSpeedArray[i] === 'v_m_s_20_25') ||
            (this.excludeViolationTypes.includes(3) && violationSpeedArray[i] === 'v_m_s_25_30') ||
            (this.excludeViolationTypes.includes(4) && violationSpeedArray[i] === 'v_m_s_30_50') ||
            (this.excludeViolationTypes.includes(5) && violationSpeedArray[i] === 'v_m_s_50u')
          ) continue
          this[targetData][idArray[target]]['data']['all'][dds['date']]['v_total'] += dds[violationSpeedArray[i]]
          this[targetData][idArray[target]]['data']['general'][dds['date']]['v_total'] += dds[violationSpeedGeneralArray[i]]
          this[targetData][idArray[target]]['data']['narrow'][dds['date']]['v_total'] += dds[violationSpeedNarrowArray[i]]
          this[targetData][idArray[target]]['data']['all'][dds['date']]['v_m_s'] += dds[violationSpeedArray[i]]
          this[targetData][idArray[target]]['data']['general'][dds['date']]['v_m_s'] += dds[violationSpeedGeneralArray[i]]
          this[targetData][idArray[target]]['data']['narrow'][dds['date']]['v_m_s'] += dds[violationSpeedNarrowArray[i]]
          this[targetData][idArray[target]]['data']['all'][dds['date']][violationSpeedArray[i]] += dds[violationSpeedArray[i]]
          this[targetData][idArray[target]]['data']['general'][dds['date']][violationSpeedArray[i]] += dds[violationSpeedGeneralArray[i]]
          this[targetData][idArray[target]]['data']['narrow'][dds['date']][violationSpeedArray[i]] += dds[violationSpeedNarrowArray[i]]
        }

        this[targetData][idArray[target]]['data']['all'][dds['date']]['v_a_t_s'] += dds['v_a_t_s']
        this[targetData][idArray[target]]['data']['all'][dds['date']]['v_a_r_s'] += dds['v_a_r_s']
        this[targetData][idArray[target]]['data']['all'][dds['date']]['a_t_1'] += dds['a_t_1']
        this[targetData][idArray[target]]['data']['all'][dds['date']]['a_t_2'] += dds['a_t_2']
        this[targetData][idArray[target]]['data']['all'][dds['date']]['a_t_3'] += dds['a_t_3']
        this[targetData][idArray[target]]['data']['all'][dds['date']]['a_t_4'] += dds['a_t_4']
        this[targetData][idArray[target]]['data']['all'][dds['date']]['a_t_51'] += dds['a_t_51']
        this[targetData][idArray[target]]['data']['all'][dds['date']]['a_t_52'] += dds['a_t_52']
        this[targetData][idArray[target]]['data']['all'][dds['date']]['analyzed_datetime'] = dds['analyzed_datetime']
      }
    },
    updateMonthrySummary(month) {
      const _this = this
      this.dataLoading = true
      const postData = {
        month: month,
        company_id: this.selectedCompany,
      }
      const send = Vue.prototype.$api.send('post', 'update_monthly_drive_data_summary', postData)
      send.then(response => {
        _this.$notification['success']({
          message: 'データの更新が完了しました。',
        })
        this.getSummaryData()
      })
        .catch(error => {
          this.dataLoading = false
          console.log(error)
        })
    },
    outputCSV() {
      const _this = this
      const monthList = '月,1月,2月,3月,4月,5月,6月,7月,8月,9月,10月,11月,12月\n'
      let csv = '\ufeff'
      csv += this.selectedCompanyName + ' ' + this.selectedYear + '年のデータ\n\n'

      csv += 'データ更新タイミング\n\n'
      csv += monthList
      csv += '更新日時,'
      Object.keys(_this.companyData[0]['data']['all']).forEach(function (key) {
        csv += _this.companyData[0]['data']['all'][key].analyzed_datetime + ','
      })
      csv += '\n\n'

      csv += '全社データ\n'
      Object.keys(_this.roadType).forEach(function (road) {
        csv += _this.roadType[road] + '\n'

        csv += monthList
        csv += '全体遵守率,'
        Object.keys(_this.companyData[0]['data'][road]).forEach(function (month) {
          const c = _this.companyData[0]['data'][road][month]
          csv += _this.outputPercentValue((((c['r_total'] - c['v_total']) / c['r_total']) * 100).toFixed(3)) + ','
        })
        csv += '\n'

        csv += '違反種類別遵守率\n'
        csv += '速度超過,'
        Object.keys(_this.companyData[0]['data'][road]).forEach(function (month) {
          const c = _this.companyData[0]['data'][road][month]
          csv += _this.outputPercentValue((((c['r_m_s'] - c['v_m_s']) / c['r_m_s']) * 100).toFixed(3)) + ','
        })
        csv += '\n'
        csv += '一時不停止,'
        Object.keys(_this.companyData[0]['data'][road]).forEach(function (month) {
          const c = _this.companyData[0]['data'][road][month]
          csv += _this.outputPercentValue((((c['r_t_s'] - c['v_t_s']) / c['r_t_s']) * 100).toFixed(3)) + ','
        })
        csv += '\n'
        csv += '通行禁止,'
        Object.keys(_this.companyData[0]['data'][road]).forEach(function (month) {
          const c = _this.companyData[0]['data'][road][month]
          csv += _this.outputPercentValue((((c['r_o'] - c['v_o']) / c['r_o']) * 100).toFixed(3)) + ','
        })
        csv += '\n'
        csv += '踏切不停止,'
        Object.keys(_this.companyData[0]['data'][road]).forEach(function (month) {
          const c = _this.companyData[0]['data'][road][month]
          csv += _this.outputPercentValue((((c['r_r_s'] - c['v_r_s']) / c['r_r_s']) * 100).toFixed(3)) + ','
        })
        csv += '\n'

        csv += '違反種類別遵守率\n'
        csv += '20未満,'
        Object.keys(_this.companyData[0]['data'][road]).forEach(function (month) {
          const c = _this.companyData[0]['data'][road][month]
          csv += _this.outputPercentValue(((c['v_m_s_u20'] / c['v_m_s']) * 100).toFixed(3)) + ','
        })
        csv += '\n'
        csv += '20以上25未満,'
        Object.keys(_this.companyData[0]['data'][road]).forEach(function (month) {
          const c = _this.companyData[0]['data'][road][month]
          csv += _this.outputPercentValue(((c['v_m_s_20_25'] / c['v_m_s']) * 100).toFixed(3)) + ','
        })
        csv += '\n'
        csv += '25以上30未満,'
        Object.keys(_this.companyData[0]['data'][road]).forEach(function (month) {
          const c = _this.companyData[0]['data'][road][month]
          csv += _this.outputPercentValue(((c['v_m_s_25_30'] / c['v_m_s']) * 100).toFixed(3)) + ','
        })
        csv += '\n'
        csv += '30以上50未満,'
        Object.keys(_this.companyData[0]['data'][road]).forEach(function (month) {
          const c = _this.companyData[0]['data'][road][month]
          csv += _this.outputPercentValue(((c['v_m_s_30_50'] / c['v_m_s']) * 100).toFixed(3)) + ','
        })
        csv += '\n'
        csv += '50以上,'
        Object.keys(_this.companyData[0]['data'][road]).forEach(function (month) {
          const c = _this.companyData[0]['data'][road][month]
          csv += _this.outputPercentValue(((c['v_m_s_50u'] / c['v_m_s']) * 100).toFixed(3)) + ','
        })
        csv += '\n'
      })
      csv += '\n'

      const dataArray = {
        branchData: '支店',
        vehicleData: '車両',
        userData: 'ユーザー',
      }
      Object.keys(dataArray).forEach(function (dataType) {
        csv += dataArray[dataType] + '\n'
        Object.keys(_this[dataType]).forEach(function (target) {
          csv += _this[dataType][target]['info']['name'] + '\n'
          Object.keys(_this.roadType).forEach(function (road) {
            csv += _this.roadType[road] + '\n'

            csv += monthList
            csv += '全体遵守率,'
            Object.keys(_this[dataType][target]['data'][road]).forEach(function (month) {
              const c = _this[dataType][target]['data'][road][month]
              csv += _this.outputPercentValue((((c['r_total'] - c['v_total']) / c['r_total']) * 100).toFixed(3)) + ','
            })
            csv += '\n'

            csv += '違反種類別遵守率\n'
            csv += '速度超過,'
            Object.keys(_this[dataType][target]['data'][road]).forEach(function (month) {
              const c = _this[dataType][target]['data'][road][month]
              csv += _this.outputPercentValue((((c['r_m_s'] - c['v_m_s']) / c['r_m_s']) * 100).toFixed(3)) + ','
            })
            csv += '\n'
            csv += '一時不停止,'
            Object.keys(_this[dataType][target]['data'][road]).forEach(function (month) {
              const c = _this[dataType][target]['data'][road][month]
              csv += _this.outputPercentValue((((c['r_t_s'] - c['v_t_s']) / c['r_t_s']) * 100).toFixed(3)) + ','
            })
            csv += '\n'
            csv += '通行禁止,'
            Object.keys(_this[dataType][target]['data'][road]).forEach(function (month) {
              const c = _this[dataType][target]['data'][road][month]
              csv += _this.outputPercentValue((((c['r_o'] - c['v_o']) / c['r_o']) * 100).toFixed(3)) + ','
            })
            csv += '\n'
            csv += '踏切不停止,'
            Object.keys(_this[dataType][target]['data'][road]).forEach(function (month) {
              const c = _this[dataType][target]['data'][road][month]
              csv += _this.outputPercentValue((((c['r_r_s'] - c['v_r_s']) / c['r_r_s']) * 100).toFixed(3)) + ','
            })
            csv += '\n'

            csv += '違反種類別遵守率\n'
            csv += '20未満,'
            Object.keys(_this[dataType][target]['data'][road]).forEach(function (month) {
              const c = _this[dataType][target]['data'][road][month]
              csv += _this.outputPercentValue(((c['v_m_s_u20'] / c['v_m_s']) * 100).toFixed(3)) + ','
            })
            csv += '\n'
            csv += '20以上25未満,'
            Object.keys(_this[dataType][target]['data'][road]).forEach(function (month) {
              const c = _this[dataType][target]['data'][road][month]
              csv += _this.outputPercentValue(((c['v_m_s_20_25'] / c['v_m_s']) * 100).toFixed(3)) + ','
            })
            csv += '\n'
            csv += '25以上30未満,'
            Object.keys(_this[dataType][target]['data'][road]).forEach(function (month) {
              const c = _this[dataType][target]['data'][road][month]
              csv += _this.outputPercentValue(((c['v_m_s_25_30'] / c['v_m_s']) * 100).toFixed(3)) + ','
            })
            csv += '\n'
            csv += '30以上50未満,'
            Object.keys(_this[dataType][target]['data'][road]).forEach(function (month) {
              const c = _this[dataType][target]['data'][road][month]
              csv += _this.outputPercentValue(((c['v_m_s_30_50'] / c['v_m_s']) * 100).toFixed(3)) + ','
            })
            csv += '\n'
            csv += '50以上,'
            Object.keys(_this[dataType][target]['data'][road]).forEach(function (month) {
              const c = _this[dataType][target]['data'][road][month]
              csv += _this.outputPercentValue(((c['v_m_s_50u'] / c['v_m_s']) * 100).toFixed(3)) + ','
            })
            csv += '\n'
          })
          csv += '\n'
        })
        csv += '\n\n'
      })

      var blob = new Blob([csv], { type: 'text/plain' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'drive_data_summary.csv'
      link.click()
    },
    outputPercentValue: function (val) {
      if (val === 'NaN') return ''
      else return val + ' %'
    },
  },
}

</script>

<style>
.list-name {
  font-size: 1.5rem;
}
table {
  width: 100%;
}
table th, table td {
  border: 1px #c0c0c0 solid;
}
</style>
